import React from 'react';
import logo from './logo.png';
import './App.css';

function App() {

  // fwx.fyne.general.show()
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Fyneworks
        </p>
        <a
          className="App-link open-general-dialog"
          href="#general-dialog"
          rel="general-dialog"
        >
          Contact Us
        </a>
      </header>
    </div>
  );
}

export default App;
